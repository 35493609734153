import React, { ChangeEventHandler } from 'react';
import { Box, FormControl, InputGroup, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';
import { CustomInputRad } from '../../atoms/ContactUsForm/CustomInputRad';

interface CustomInputRadioProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  options?: string; // Comma-separated string of options
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  value?: string;
}

export const CustomInputRadio: React.FC<CustomInputRadioProps> = ({
  question,
  placeholder,
  required,
  description,
  options = '',
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  onChange,
  value,
}) => {
  const inputId = 'Radio-field'; // Unique id for the input field

  return (
    <Box marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />{' '}
        <Description description={description} fontSize={descriptionFontSize} />{' '}
        <InputGroup size="md">
          <CustomInputRad
            id={inputId}
            options={options} // Pass options as a comma-separated string
            placeholder={placeholder}
            isRequired={required}
            onChange={onChange}
            value={value}
            variant="outline"
            bgColor="#FFFFFF"
            height="40px"
            fontSize="md"
            marginTop={marginTop}
          />
        </InputGroup>
      </FormControl>
    </Box>
  );
};
