import { useEffect, useState } from 'react';
import { Box, HStack, Icon, Stack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomInput, CustomText } from '../../atoms';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface HeaderSettingsMoleculeProps {
  configs: {
    headerlogoUrl: string;
    showMainHeader: boolean;
    showSubHeader: boolean;
    mainHeaderBgColor: string;
    mainHeaderTextColor: string;
  };
  onConfigChange: (key: string, value: any) => void;
}

export const HeaderSettingsMolecule: React.FC<HeaderSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false); // State for expanding/collapsing cards
  const [showMainHeader, setShowMainHeader] = useState(configs.showMainHeader);
  const [showSubHeader, setShowSubHeader] = useState(configs.showSubHeader);

  // Sync local state with incoming configs on mount and when configs change
  useEffect(() => {
    setShowMainHeader(configs.showMainHeader);
    setShowSubHeader(configs.showSubHeader);
  }, [configs.showMainHeader, configs.showSubHeader]);

  // Toggle collapse and expand
  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      {/* Section Header */}
      <HStack
        justifyContent="space-between"
        border="1px solid #dedede"
        p="3"
        borderRadius="10px"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" fontSize="md">
          Header
        </CustomText>
        {/* Collapse/Expand Button */}
        <Icon
          as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          boxSize={6}
          onClick={toggleExpandCollapse}
          cursor="pointer"
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
        />
      </HStack>
      {/* Conditional rendering of menu cards based on expand/collapse state */}
      {isExpanded && (
        <Stack spacing={4}>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomInput
              label="Header Logo URL"
              value={configs.headerlogoUrl}
              onChange={e => onConfigChange('headerlogoUrl', e.target.value)}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              label="Show Main Header"
              value={showMainHeader}
              onChange={value => {
                setShowMainHeader(value); // Update local state
                onConfigChange('showMainHeader', value); // Notify parent of the change
              }}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              label="Show Sub Header"
              value={showSubHeader}
              onChange={value => {
                setShowSubHeader(value); // Update local state
                onConfigChange('showSubHeader', value); // Notify parent of the change
              }}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Main Header Background Color"
              value={configs.mainHeaderBgColor}
              onChange={color => onConfigChange('mainHeaderBgColor', color)}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Main Header Text Color"
              value={configs.mainHeaderTextColor}
              onChange={color => onConfigChange('mainHeaderTextColor', color)}
            />
          </HStack>
        </Stack>
      )}
    </Box>
  );
};
