import { useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  Stack,
  Switch,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { CustomText } from '../../atoms';

interface FooterSettingsMoleculeProps {
  configs: any;
  onFooterSettingsChange: (key: string, value: any) => void;
}

export const FooterSettingsMolecule: React.FC<FooterSettingsMoleculeProps> = ({
  configs,
  onFooterSettingsChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      {/* Section Header */}
      <HStack
        justifyContent="space-between"
        border="1px solid #dedede"
        p="3"
        borderRadius="10px"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" fontSize="md">
          Footer Settings
        </CustomText>
        <Icon
          as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          boxSize={6}
          onClick={toggleExpandCollapse}
          cursor="pointer"
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
        />
      </HStack>

      {isExpanded && (
        <Stack spacing={4}>
          {/* Show Footer */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <CustomText>Show Footer</CustomText>
            <Switch
              isChecked={configs.showFooter}
              onChange={e => onFooterSettingsChange('showFooter', e.target.value)}
            />
          </HStack>

          {/* Footer Logo URL */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <CustomText>Footer Logo URL</CustomText>
            <Input
              value={configs?.footerLogoUrl}
              onChange={e => onFooterSettingsChange('footerLogoUrl', e.target.value)}
              placeholder="Enter footer logo URL"
            />
          </HStack>

          {/* Footer Background Color */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <ColorPickerAtom
              label="Footer Background Color"
              value={configs?.footerBackgroundColor}
              onChange={color => onFooterSettingsChange('footerBackgroundColor', color)}
            />
          </HStack>

          {/* Footer Text Color */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <ColorPickerAtom
              label="Footer Text Color"
              value={configs?.footerTextColor}
              onChange={color => onFooterSettingsChange('footerTextColor', color)}
            />
          </HStack>

          {/* Show Footer Logo */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <CustomText>Show Footer Logo</CustomText>
            <Switch
              isChecked={configs?.showFooterLogo}
              onChange={e => onFooterSettingsChange('showFooterLogo', e.target.checked)}
            />
          </HStack>

          {/* Show Footer Links */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <CustomText>Show Footer Links</CustomText>
            <Switch
              isChecked={configs?.showFooterLinks}
              onChange={e => onFooterSettingsChange('showFooterLinks', e.target.checked)}
            />
          </HStack>

          {/* Footer Logo Size */}
          <HStack p={4} justifyContent="space-between" alignItems="center">
            <CustomText>Footer Logo Size</CustomText>
            <Slider
              value={parseInt(configs?.footerLogoSize?.base || '30', 10)}
              min={10}
              max={100}
              onChange={val =>
                onFooterSettingsChange('footerLogoSize', { base: `${val}%`, lg: `${val / 3}%` })
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Text fontSize="xs">{configs?.footerLogoSize?.base || '30%'}</Text>
              </SliderThumb>
            </Slider>
          </HStack>
        </Stack>
      )}
    </Box>
  );
};
