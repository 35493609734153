import { Button as ChakraButton, ButtonProps, SystemStyleObject } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface CustomButtonProps extends ButtonProps {
  onClick?: () => void;
  children?: ReactNode;
  variant?: string; // Chakra's variant options or custom ones
  size?: string;
  colorScheme?: string;
  fontSize?: string;
  fontWeight?: string;
  textColor?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  padding?: string;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  hoverValue?: SystemStyleObject;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isDisabled?: boolean;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  variant = 'solid', // Default variant
  size = 'md', // Default size
  colorScheme = 'black', // Default color scheme
  fontSize = 'md', // Default font size
  fontWeight = 'normal', // Default font weight
  textColor, // Text color changes based on variant
  backgroundColor, // Background color for outline button
  border, // Outline border for outline variant
  borderColor, // Border color for outline variant
  borderRadius = 'md', // Default border radius
  padding = '8px 16px', // Default padding
  icon,
  mx,
  hoverValue,
  type = 'button',
  isDisabled,
  ...props // Spread the rest of the props for flexibility
}) => {
  return (
    <ChakraButton
      type={type}
      leftIcon={icon}
      onClick={onClick}
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={textColor}
      backgroundColor={backgroundColor}
      border={border}
      borderColor={borderColor}
      borderRadius={borderRadius}
      padding={padding}
      mx={mx}
      _hover={hoverValue}
      isDisabled={isDisabled}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props} // Allow other Chakra UI button props to be passed
    >
      {children}
    </ChakraButton>
  );
};
