/* eslint-disable */
export function initializeHelpshiftChat(
  platformId: string,
  domain: string,
  language: string,
  username: string,
  userEmail: string,
  property?: string,
  userFirstMessage?: string,
  marginTop?: string,
) {
  (window as any).helpshiftConfig = {
    platformId: platformId,
    domain: domain,
    language: language,
    userName: username,
    userEmail: userEmail,
  };

  console.log('typeof');
  console.log(platformId, domain, language, username, userEmail, property, userFirstMessage);
  console.log(typeof (window as any).Helpshift);

  if (typeof (window as any).Helpshift !== 'function') {
    console.log('inside if');
    const Helpshift = function (p0?: string) {
      (Helpshift as any).q.push(arguments);
    };
    (Helpshift as any).q = [];
    (window as any).Helpshift = Helpshift;

    const scriptId = 'hs-chat';
    if (!document.getElementById(scriptId)) {
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.id = scriptId;
      scriptElement.src = 'https://webchat.helpshift.com/latest/webChat.js';

      const waitForIframeLauncher = (retries: number, interval: number) => {
        const checkElement = () => {
          const el = document.getElementById('hs-web-sdk-iframe-launcher');
          console.log('found it?', el);
          if (el) {
            el.style.top = '80%';
          } else if (retries > 0) {
            setTimeout(() => waitForIframeLauncher(retries - 1, interval), interval);
          } else {
            console.error('hs-web-sdk-iframe-launcher not found after retries');
          }
        };
        checkElement();
      };

      scriptElement.addEventListener('load', () => {
        (window as any).Helpshift('init');

        // Execute the property logic
        switch (property) {
          case 'showWidget':
            (window as any).Helpshift('show');
            break;
          case 'hideWidget':
            (window as any).Helpshift('hide');
            break;
          case 'openWidget':
            (window as any).Helpshift('open');
            break;
          case 'closeWidget':
            (window as any).Helpshift('close');
            break;
          case 'showWidget,openWidget':
          case 'openWidget,showWidget,':
            (window as any).Helpshift('show');
            (window as any).Helpshift('open');
            break;
          default:
            console.log('Invalid property value:', property);
            break;
        }

        // Check for the iframe launcher after Helpshift initializes
        waitForIframeLauncher(10, 500); // Retry 10 times with 500ms interval
      });

      const firstScript = document.getElementsByTagName('script')[0];
      if (firstScript.parentNode) {
        firstScript.parentNode.insertBefore(scriptElement, firstScript);
      }
      if (userFirstMessage && window.helpshiftConfig) {
        console.log('in user first message');
        window.helpshiftConfig.initialUserMessage = userFirstMessage;
        window.helpshiftConfig.initiateChatOnLoad = true;
        Helpshift('updateHelpshiftConfig');
      }
    }
  } else {
    // Handle cases where Helpshift is already defined
    console.log('inside else');
    switch (property) {
      case 'showWidget':
        console.log('show');
        (window as any).Helpshift('show');
        break;
      case 'hideWidget':
        (window as any).Helpshift('hide');
        break;
      case 'openWidget':
        console.log('open');
        (window as any).Helpshift('open');
        break;
      case 'closeWidget':
        (window as any).Helpshift('close');
        break;
      case 'showWidget,openWidget':
      case 'openWidget,showWidget,':
        console.log('show and open');
        (window as any).Helpshift('show');
        (window as any).Helpshift('open');
        break;
      default:
        console.log('Invalid property value:', property);
        break;
    }

    const waitForIframeLauncher = (retries: number, interval: number) => {
      const checkElement = () => {
        const el = document.getElementById('hs-web-sdk-iframe-launcher');
        console.log('found it?', el);
        if (el) {
          if (marginTop) {
            el.style.top = marginTop;
          } else {
            el.style.top = '90%';
          }
        } else if (retries > 0) {
          setTimeout(() => waitForIframeLauncher(retries - 1, interval), interval);
        } else {
          console.error('hs-web-sdk-iframe-launcher not found after retries');
        }
      };
      checkElement();
    };

    waitForIframeLauncher(10, 500); // Retry 10 times with 500ms interval
    if (userFirstMessage && window.helpshiftConfig) {
      console.log('in user first message');
      window.helpshiftConfig.initialUserMessage = userFirstMessage;
      window.helpshiftConfig.initiateChatOnLoad = true;
      (window as any).Helpshift('updateHelpshiftConfig');
    }
  }
}
