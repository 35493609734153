import React from 'react'; // Import useState
import { Box, Stack, Center, ResponsiveValue } from '@chakra-ui/react';
import { CustomImage } from '../atoms/CustomImage'; // Reusing the existing BannerImage atom
import { CustomText } from '../atoms/CustomText'; // Reusing the existing Typography atom
import { SearchBar } from './SearchBar';

// Define props interface
interface BannerWithSearchBarProps {
  imageSrc: string | undefined; // Source URL for the background image
  altText: string; // Alt text for the image
  bannerText: string; // Text to display on the banner
  placeholderFontSize?: ResponsiveValue<string>; // Font size for the placeholder text
  placeholderFontFamily?: string; // Font family for the placeholder text
  placeholderText: string; // Placeholder text for the search input
  placeholderColor?: string;
  searchBarTextColor?: string;
  showOverlay: boolean | undefined;
  showSearchBar: boolean | undefined;
}

export const BannerWithSearchBar: React.FC<BannerWithSearchBarProps> = ({
  imageSrc,
  altText,
  bannerText,
  placeholderFontSize,
  placeholderFontFamily,
  placeholderText,
  placeholderColor,
  searchBarTextColor,
  showOverlay,
  showSearchBar = true,
  // searchBarWidth,
}) => {
  return (
    <Box
      position="relative"
      height={{ base: '40vh', md: '50vh' }} // Responsive height
      overflow="visible"
      px={{ base: '2vw', md: '1vw', lg: '15vw' }}
    >
      {/* Background Image (Atom) */}
      <CustomImage src={imageSrc} alt={altText} objectFit="fill" />

      {/* Overlay for text and input */}
      <Box
        position="absolute"
        bottom="0"
        right="0"
        width="100%"
        height="50%"
        bg={showOverlay ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={{ lg: '15vw' }}
        // px={{ base: '2vw', md: '1vw', lg: '15vw' }}
      >
        {showSearchBar && (
          <Center>
            <Stack spacing={6} width="100%">
              {/* Heading Text (Atom) */}
              <CustomText
                variant="h1"
                color="white"
                textAlign="center"
                fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }} // Responsive font size
                fontWeight="700"
              >
                {bannerText}
              </CustomText>

              <SearchBar
                searchBarTextColor={searchBarTextColor}
                placeholderText={placeholderText}
                placeholderColor={placeholderColor}
                placeholderFontSize={placeholderFontSize}
                placeholderFontFamily={placeholderFontFamily}
              />
            </Stack>
          </Center>
        )}
      </Box>
    </Box>
  );
};
