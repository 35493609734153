// LandingPage.tsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MenuCardList } from '../organisms/MenuCardList';
import { NewsSection } from '../organisms/NewsSection';
import { PopularArticles } from '../organisms/PopularArticles';
import { BannerWithSearchBar } from '../organisms/BannerWithSearchBar';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { SectionGrid } from '../organisms/SectionGrid';
import { initializeHelpshiftChat } from '../../utils';

const PLATFORM_ID = process.env.REACT_APP_PLATFORM_ID || '';
const DOMAIN = process.env.REACT_APP_DOMAIN || '';
const LANGUAGE = process.env.REACT_APP_LANGUAGE || '';

export const LandingPage: React.FC = () => {
  const { settings, sectionsData, faqs, language } = useSettings(); // Destructure from context
  const navigate = useNavigate();
  // Dummy image and functions for missing variables
  initializeHelpshiftChat(
    PLATFORM_ID,
    DOMAIN,
    LANGUAGE,
    'dummy name',
    'dummy email id',
    'hideWidget',
  );
  // const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const query = e.target.value;

  //   if (query.trim().length > 0) {
  //     // Request options
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow' as RequestRedirect,
  //     };

  //     // Perform the fetch
  //     fetch(
  //       `https://scopely.helpshift.com/xhr/support/search/?query=${encodeURIComponent(
  //         query,
  //       )}&l=en&is_sdkx=false&is_preview=false&platform_id=&hc_mode=hc&faq_filter=JTdCJTIyYXBwX2lkJTIyJTNBJTIycmF2aS1kZW1vX2FwcF8yMDIyMDMzMTAwNTAyOTY1OC1jOTJjOTFhMDdjYTVjNWUlMjIlN0Q%3D`,
  //       requestOptions,
  //     )
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         return response.text(); // Return the response as text
  //       })
  //       .then(result => {
  //         // eslint-disable-next-line
  //         console.log('Search Results:', result); // Log the search results
  //       })
  //       .catch(error => {
  //         // eslint-disable-next-line
  //         console.error('Error fetching search results:', error);
  //       });
  //   }
  // };
  const onClickContactUs = () => {
    // eslint-disable-next-line
    console.log('Contact Us button clicked');
    navigate('/contactUs');
  };

  const onClickMenuCard = (menuCardLink: string) => {
    window.open(menuCardLink, '_self');
  };

  const onClickPopularArticle = (publish_id: string | number) => {
    const faq = faqs?.find(item => item.publish_id === publish_id);
    if (faq) {
      const translation = faq.translations[language];
      if (translation) {
        const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/${language}/faq/${publish_id}-${formattedTitle}`);
      }
    }
  };

  return (
    <Layout>
      <Box>
        {/* Banner with Search Bar */}
        {settings?.configs.showBanner && (
          <BannerWithSearchBar
            imageSrc={settings?.configs.bannerImageUrl}
            altText="Banner image"
            bannerText="How can we help you?"
            placeholderText="Search for articles"
            placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }} // Responsive font size
            placeholderColor={settings?.configs.placeholderColor}
            searchBarTextColor={settings?.configs.searchBarTextColor}
            showOverlay={settings?.configs.showOverlay}
            showSearchBar={settings?.configs.showSearchBar}
          />
        )}

        {/* Main content with responsive padding */}
        <Box px={{ base: '2vw', md: '1vw', lg: '15vw' }}>
          {/* Menu Cards */}
          {settings?.configs.showMenuCards && (
            <MenuCardList
              items={settings?.data.menuCards}
              menuCardsBgColor={settings?.configs.secondaryBgColor ?? ''}
              menuCradsTextColor={settings?.configs.secondaryTextColor ?? ''}
              onClickMenuCard={(id: string) => onClickMenuCard(id)}
            />
          )}
          {/* News Section */}
          {settings?.configs.showNewsSection && (
            <NewsSection
              newsArticles={settings?.data.newsArticles}
              newsArticleBgColor={settings?.configs.primaryBgColor}
              newsArticleTextColor={settings?.configs.primaryTextColor}
              newsSectionBgColor={settings?.configs.secondaryBgColor}
              newsSectionHeadingTextColor={settings?.configs.secondaryTextColor}
              paginationSelectedPageBgColor={settings?.configs.paginationSelectedPageBgColor}
              paginationSelectedPageTextColor={settings?.configs.paginationSelectedPageTextColor}
            />
          )}

          {/* Popular Articles */}
          {settings?.configs.showPopularArticles && (
            <PopularArticles
              faqArticles={settings?.data.popularArticles}
              bgColor={settings?.configs.primaryBgColor}
              faqArticlesTextColor={settings?.configs.secondaryTextColor ?? ''}
              faqArticlesBgColor={settings?.configs.secondaryBgColor ?? ''}
              primaryTextColor={settings?.configs.primaryTextColor ?? ''}
              onClickPopularArticle={onClickPopularArticle}
            />
          )}

          {/* section wise faq display */}
          <SectionGrid
            textColor={settings?.configs.secondaryTextColor}
            sectionBgColor={settings?.configs.secondaryBgColor}
            sections={sectionsData}
            sectionIcons={settings?.data.sectionIcons}
          />
        </Box>

        {/* Contact Button Section */}
        {settings?.configs.showContactUsButton && (
          <ContactBtnSection
            onButtonClick={onClickContactUs}
            variant="h1"
            color={settings?.configs.contactUsButtonTextColor}
            buttonBgColor={settings?.configs.contactUsButtonBgColor}
            needHelpText={settings?.configs.needHelptext}
            needHelpTextColor={settings?.configs.needHelpTextColor}
            mt="4vh"
          />
        )}
      </Box>
    </Layout>
  );
};
