import React, { ChangeEventHandler, useState } from 'react';
import {
  FormControl,
  InputGroup,
  InputRightElement,
  Button,
  ResponsiveValue,
} from '@chakra-ui/react';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';
import { CustomInput } from '../../atoms/ContactUsForm/CustomInput'; // Import the Chakra-based CustomInput

interface CustomInputPasswordProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

export const CustomInputPassword: React.FC<CustomInputPasswordProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  onChange,
  value,
}) => {
  const [show, setShow] = useState(false);
  const inputId = 'password-field'; // Unique id for the input field

  const handleClick = () => setShow(!show); // Toggle visibility

  return (
    <div>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <InputGroup size="md">
          <CustomInput
            id={inputId} // Set the id for the input field
            isRequired={required}
            placeholder={placeholder}
            onChange={onChange}
            size="md"
            value={value}
            type={show ? 'text' : 'password'} // Toggle the type based on `show`
            isDisabled={false}
            variant="outline"
            bgColor="#FFFFFF"
            height="40px"
            paddingLeft="10px"
            fontSize="md"
            marginTop={marginTop}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick} marginTop="4rem">
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </div>
  );
};
