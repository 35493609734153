// src/components/molecules/NewsCard.tsx
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import tinycolor from 'tinycolor2';
import { CustomText, CustomImage } from '../atoms';

interface NewsCardProps {
  imageUrl?: string; // URL of the image
  title: string; // Title of the news card
  date: string; // Date to display
  bgColor: string | undefined; // Background color of the card
  textColor?: string; // Text color of the card (optional, default is "black")
  id: string | number;
  onClickNewsItem: (id: string | number) => void;
}

export const NewsCard: React.FC<NewsCardProps> = ({
  id,
  imageUrl,
  title,
  date,
  bgColor,
  textColor = 'black',
  onClickNewsItem,
}) => {
  // Adjust color for the date (lighter version of textColor)
  const dateColor = tinycolor(textColor).lighten(40).toString();

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="1"
      m="2"
      backgroundColor={bgColor}
      color={textColor}
      position="relative" // Ensures date can be positioned in bottom-right
      height="100%"
      _hover={{ boxShadow: 'md', transform: 'scale(1.05)' }}
      onClick={() => onClickNewsItem(id)}
    >
      {/* Image */}
      {imageUrl && <CustomImage src={imageUrl} alt={title} height="50%" objectFit="fill" />}

      {/* Flexbox to align the title at the top */}
      <Flex direction="column" justify="space-between" height="50%" position="relative">
        <Text fontWeight="bold" noOfLines={3}>
          {title}
        </Text>
        {/* Date pushed to bottom-right corner */}
        <Box position="absolute" bottom="5%">
          <CustomText
            variant="body"
            color={dateColor} // Use lighter color for the date
          >
            {date}
          </CustomText>
        </Box>
      </Flex>
    </Box>
  );
};
