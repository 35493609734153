// SettingsContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { NewsArticle } from '../components/organisms/NewsSection';
import { FaqArticle } from '../components/organisms/PopularArticles';
import { MenuCardItem } from '../components/organisms/MenuCardList';
import { FooterLink } from '../components/organisms/Footer';

interface Settings {
  configs: Configs;
  data: Data;
}

interface Configs {
  primaryBgColor: string;
  secondaryBgColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  font: string;

  showHeader: boolean;
  mainHeaderBgColor: string;
  mainHeaderTextColor: string;
  headerlogoUrl: string; // Allow null for optional image
  showMainHeader: boolean;
  showSubHeader: boolean;
  showHeaderLogo: boolean;

  showBanner: boolean;
  showSearchBar: boolean;
  searchBarTextColor: string;
  searchBarBgColor?: string;
  placeholderColor?: string;
  showOverlay: boolean;
  bannerImageUrl: string;
  bannerBgColor: string;
  bannerTextColor: string;
  bannerTextSize?: string;

  showNewsSection: boolean;
  paginationSelectedPageBgColor?: string;
  paginationSelectedPageTextColor?: string;

  showMenuCards: boolean;
  showPopularArticles: boolean;

  showFooter: boolean;
  footerLogoUrl: string;
  footerBackgroundColor: string;
  footerTextColor: string;
  showFooterLogo: boolean;
  showFooterLinks: boolean;
  footerLogoSize?: { base: string; lg: string };

  showContactUsButton: boolean;
  contactUsButtonBgColor: string;
  contactUsButtonTextColor: string;
  needHelpTextColor?: string;
  needHelptext?: string;
}

export interface Section {
  name: string;
  articles: string[];
}

export interface SectionData {
  section_id: string;
  section_name: string;
  faq_data: { publish_id: string; faq_title: string; faq_body: string }[];
}

export interface SectionIcons {
  id: string;
  name: string;
  icon: string;
}

interface Data {
  sectionIcons: SectionIcons[];
  gameTitle: string;
  platforms: string[]; // Array of string for platforms
  languages: string[]; // Array of string for languages
  newsArticles: NewsArticle[]; // Assume NewsArticle type exists elsewhere
  popularArticles: FaqArticle[]; // Assume FaqArticle type exists elsewhere
  menuCards: MenuCardItem[]; // Assume MenuCard type exists elsewhere
  footerLinks: FooterLink[]; // Assume FooterLink type exists elsewhere
  footerNote: string;
  sections_data: SectionData[];
}

export interface FAQ {
  section_id: string;
  'published?': boolean;
  publish_id: string;
  platform_types: string[];
  slug: string;
  issue_tags?: string[];
  app_id: string;
  updated_at?: number;
  id: string;
  last_updated_by?: string;
  created_at?: number;
  translations: Record<string, any>;
  section_translations: Record<string, { title: string }>;
  linked_faq_ids?: string[];
}

export interface FormField {
  question: string;
  description?: string;
  placeholder?: string;
  required: boolean;
  type:
    | 'text'
    | 'email'
    | 'tel'
    | 'date'
    | 'password'
    | 'checkbox'
    | 'dropdown'
    | 'file'
    | 'radio'
    | 'number'
    | 'url'
    | 'heading';
  multiple?: boolean;
  options?: string;
  height?: string;
}

interface SettingsContextType {
  settings: Settings | null;
  setSettings: React.Dispatch<React.SetStateAction<Settings | null>>;
  saveSettings: (newSettings: Settings) => Promise<void>;
  faqs: FAQ[] | null;
  appId: string;
  language: string;
  setFaqs: React.Dispatch<React.SetStateAction<FAQ[] | null>>;
  sectionsData: SectionData[] | null;
  setSectionsData: React.Dispatch<React.SetStateAction<SectionData[] | null>>;
  contactUsFormData: FormField[] | null;
  setContactUsFormData: React.Dispatch<React.SetStateAction<FormField[] | null>>;
}

// Create a context for the settings
export const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

// Create a custom hook to access settings
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

function getSectionWiseFAQs(data: FAQ[], language: string, appId: string): SectionData[] {
  const sectionsData: Record<string, SectionData> = {};
  data.forEach(faq => {
    // Filter by app_id and ensure section translations exist for the specified language
    if (faq.app_id !== appId || !faq.section_translations?.[language]) return;
    const sectionId = faq.section_id;
    const sectionTitle = faq.section_translations[language].title;

    if (!sectionsData[sectionId]) {
      // Initialize new section object if it doesn't exist
      sectionsData[sectionId] = {
        section_id: sectionId,
        section_name: sectionTitle,
        faq_data: [],
      };
    }

    // Check if FAQ translations exist for the specified language; skip if not
    if (!faq.translations?.[language]) return;

    // Add FAQ id and title to the section's faq_data list
    sectionsData[sectionId].faq_data.push({
      publish_id: faq.publish_id,
      faq_title: faq.translations[language].title,
      faq_body: faq.translations[language].body,
    });
  });

  // Convert sectionsData object to array and return
  return Object.values(sectionsData);
}

// Create a provider to wrap the Layout component and provide settings
export const SettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Settings | null>(null); // Start with null
  const [faqs, setFaqs] = useState<FAQ[] | null>(null); // State for faqs
  const [sectionsData, setSectionsData] = useState<SectionData[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [contactUsFormData, setContactUsFormData] = useState<FormField[] | null>(null);
  const [appId] = useState(process.env.REACT_APP_APP_ID || '');
  const [language] = useState('en');

  // Function to save settings to the server
  const saveSettingsToServer = async (newSettings: Settings) => {
    try {
      const response = await axios.post('/api/save-settings', newSettings);

      if (response.status === 200) {
        const updatedSettings = response.data;

        // Ensure the updated settings contain both configs and data
        setSettings(prevSettings => ({
          configs: {
            ...prevSettings?.configs, // Retain existing configs
            ...updatedSettings.configs, // Update with new config values from the response
          },
          data: updatedSettings.data ? updatedSettings.data : prevSettings?.data, // Provide a fallback for data
        }));
        // eslint-disable-next-line
        console.log('Settings saved successfully:', updatedSettings);
      } else {
        // eslint-disable-next-line
        console.error('Failed to save settings:', response);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error saving settings:', error);
    }
  };

  // New function to handle saving settings
  const saveSettings = async (newSettings: Settings) => {
    await saveSettingsToServer(newSettings);
  };

  // Use useMemo to memoize the value prop
  const value = useMemo(
    () => ({
      settings,
      setSettings,
      saveSettings,
      faqs,
      appId,
      language,
      setFaqs,
      sectionsData,
      setSectionsData,
      contactUsFormData,
      setContactUsFormData,
    }),
    [settings, setSettings, faqs, appId, language, sectionsData, contactUsFormData],
  );

  useEffect(() => {
    // Simulate API call to fetch settings
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const cdnurl = process.env.REACT_APP_CDN_URL;
        // Start both fetch requests in parallel
        const [settingsRes, faqsRes, contactUsFormRes] = await Promise.all([
          fetch(`${cdnurl}/config.json?v=2.5`),
          fetch(`${cdnurl}/all-faqs/all-faqs.json?v=2.5`),
          fetch(`${cdnurl}/contact-us-formdata.json?v=2.5`),
        ]);

        // Check if both responses are successful
        if (!settingsRes.ok || !faqsRes.ok) {
          throw new Error('One or both network responses were not ok');
        }

        // Parse both JSON responses concurrently
        const [data, faqArticles, contactUsFormFields] = await Promise.all([
          settingsRes.json(),
          faqsRes.json(),
          contactUsFormRes.json(),
        ]);

        // Process FAQs data
        const sections = getSectionWiseFAQs(faqArticles, language, appId);

        // eslint-disable-next-line
        console.log('Sections ', sections);
        // set the sections data in context
        setSectionsData(sections);

        // set the fetched faqs
        setFaqs(faqArticles);

        setContactUsFormData(contactUsFormFields);

        // Set the fetched settings
        setSettings(data);
      } catch (error) {
        // console.error('Failed to fetch settings', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []); // Empty dependency array to run on mount

  // Render only if settings is not null
  if (!settings) {
    // eslint-disable-next-line
    console.log('loading', loading);
    return <div>Loading...</div>; // Optionally show a loading message
  }
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
