import { Box, Flex, ResponsiveValue } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import { CustomImage } from '../atoms/CustomImage';
import { CustomText } from '../atoms/CustomText';
import { CustomButton } from '../atoms/CustomButton';

interface MainHeaderProps {
  logoUrl?: string; // Type for logo URL
  logoSize?: ResponsiveValue<string>;
  gameTitle: string; // Type for game title
  horizontalPadding?: ResponsiveValue<string>; // Optional horizontal padding type
  darkMode?: boolean;
  marginStart?: ResponsiveValue<string>;
  showHeaderLogo?: boolean;
  mainHeaderBgColor: string;
  mainHeaderTextColor: string;
  changeTheme?: any;
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  logoUrl,
  gameTitle,
  logoSize,
  marginStart,
  showHeaderLogo,
  mainHeaderBgColor = '#fefefe',
  mainHeaderTextColor,
  // changeTheme,
}) => {
  // const [darkMode, setDarkMode] = useState(false);

  // Toggle dark/light mode
  // const handleToggleTheme = () => {
  //   setDarkMode(!darkMode);
  //   if (!darkMode) {
  //     changeTheme('#0000ff', '#ff00ff'); // New colors when toggled on
  //   } else {
  //     changeTheme('#ff0000', '#00ff00'); // Reset to original colors when toggled off
  //   }
  // };

  return (
    <Box p={2} backgroundColor={mainHeaderBgColor}>
      <Flex
        alignItems="center"
        flexDirection="row" // Ensure everything stays in the same row
        justifyContent="space-between"
      >
        {/* Logo and Text Section */}
        <Flex alignItems="center">
          {showHeaderLogo && (
            <CustomImage
              src={logoUrl}
              alt="Logo"
              width={logoSize}
              color={mainHeaderTextColor}
              link="/"
            />
          )}
          <CustomText
            fontSize="lg"
            fontWeight="bold"
            marginStart={marginStart}
            color={mainHeaderTextColor}
          >
            {gameTitle}
          </CustomText>
        </Flex>

        {/* Login Button */}
        <CustomButton
          leftIcon={<FaUser />}
          backgroundColor={mainHeaderBgColor}
          color={mainHeaderTextColor}
          _hover={{
            boxShadow: 'lg',
            transform: 'scale(1.05)',
          }}
        >
          Login
        </CustomButton>
      </Flex>
    </Box>
  );
};
