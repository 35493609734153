import { useState } from 'react';
import { Box, HStack, Icon, Stack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { CustomText } from '../../atoms';

interface ColorSettingsMoleculeProps {
  configs: any;
  onConfigChange: (key: string, value: string) => void;
}

export const ColorSettingsMolecule: React.FC<ColorSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false); // State for expanding/collapsing cards

  // Toggle collapse and expand
  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      {/* Section Header */}
      <HStack
        justifyContent="space-between"
        border="1px solid #dedede"
        p="3"
        borderRadius="10px"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" fontSize="md">
          Color
        </CustomText>
        {/* Collapse/Expand Button */}
        <Icon
          as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          boxSize={6}
          onClick={toggleExpandCollapse}
          cursor="pointer"
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
        />
      </HStack>
      {/* Conditional rendering of menu cards based on expand/collapse state */}
      {isExpanded && (
        <Stack spacing={4}>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Primary Background Color"
              value={configs.primaryBgColor}
              onChange={color => onConfigChange('primaryBgColor', color)}
            />
          </HStack>

          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Secondary Background Color"
              value={configs.secondaryBgColor}
              onChange={color => onConfigChange('secondaryBgColor', color)}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Primary Text Color"
              value={configs.primaryTextColor}
              onChange={color => onConfigChange('primaryTextColor', color)}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Secondary Text Color"
              value={configs.secondaryTextColor}
              onChange={color => onConfigChange('secondaryTextColor', color)}
            />
          </HStack>
        </Stack>
      )}
    </Box>
  );
};
