import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../organisms/Footer';
import { Header } from '../organisms';
import { useSettings } from '../../stateManagement/SettingsContext';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();

  const { settings, setSettings } = useSettings();
  const [settingsCopy, setSettingsCopy] = useState<typeof settings | null>(null); // Store the original settings

  const handleLanguageChange = (newLanguage: string) => {
    // eslint-disable-next-line
    console.log('selected language is ', currentLanguage);
    setCurrentLanguage(newLanguage);
  };

  const onClickMyTickets = () => {
    // eslint-disable-next-line
    console.log('Clicked My Tickets');
    navigate('/playerhub');
  };

  const changeTheme = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);

    if (!darkMode) {
      // Enabling dark mode
      if (!settings) return; // Prevent changes before settings are loaded

      if (!settingsCopy) {
        setSettingsCopy({ ...settings }); // Copy the original settings before modifying
      }

      const tempSettings = {
        ...settings,
        configs: {
          ...settings?.configs,
          needHelpTextColor: '#fff',
          mainHeaderBgColor: '#000',
          mainHeaderTextColor: '#fff',
          primaryBgColor: '#000',
          secondaryBgColor: '#777',
          primaryTextColor: '#fff',
          secondaryTextColor: '#fff',
          contactUsButtonBgColor: '#faba32',
          contactUsButtonTextColor: '#000',
          searchBarBgColor: '#777',
        },
      };
      setSettings(tempSettings); // Use temporary settings to simulate dark mode
    } else if (settingsCopy) {
      setSettings(settingsCopy); // Revert to the original settings
      setSettingsCopy(null); // Clear the copied settings once reverted
    }
  };

  useEffect(() => {
    if (!settings) {
      // Fetch settings if not already loaded
      fetch('/api/settings')
        .then(response => response.json())
        .then(data => {
          setSettings(data); // Update the context state with fetched settings
        })
        .catch(error =>
          // eslint-disable-next-line
          console.error('Error fetching settings:', error),
        );
    }
  }, [settings, setSettings]);

  if (!settings) {
    return <div>Loading...</div>; // Optional loading state while fetching settings
  }

  return (
    <Box backgroundColor={settings?.configs.primaryBgColor}>
      {/* Header */}
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={settings?.data.gameTitle}
          platforms={settings?.data.platforms}
          languages={settings?.data.languages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '15vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
        />
      )}

      {/* Render children */}
      {children}

      {/* Footer */}
      <Footer
        showFooterLogo={settings?.configs?.showFooterLogo}
        gameTitle={settings?.data.gameTitle}
        showFooterLinks={settings?.configs?.showFooterLinks}
        links={settings?.data?.footerLinks}
        imageSrc={settings?.configs?.footerLogoUrl}
        logoWidth={settings?.configs?.footerLogoSize}
        footerNote={settings?.data?.footerNote}
        footerBgColor={settings?.configs?.footerBackgroundColor}
        footerTextColor={settings?.configs?.footerTextColor}
        fontSize="xs"
      />
    </Box>
  );
};
