import React, { useState } from 'react';
import { Box, FormControl, Icon, ResponsiveValue, Text, useColorModeValue } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';

interface CustomInputFileProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  multiple?: boolean;
  marginTop?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  fontSize?: string;
  descriptionFontSize?: string;
  onChange?: (files: Record<string, File>) => void;
}

export const CustomInputFile: React.FC<CustomInputFileProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  multiple = true,
  onChange,
  // value,
}) => {
  const [fileNames, setFileNames] = useState<string[]>([]); // Track selected file names
  const [dragging, setDragging] = useState(false); // Track drag state
  const dropzoneBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  const handleFiles = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files);
      const fileMap = fileArray.reduce<Record<string, File>>((acc, file) => {
        return {
          ...acc, // Create a new object at each step
          [file.name]: file,
        };
      }, {});

      setFileNames(fileArray.map(file => file.name)); // Extract and save file names
      if (onChange) onChange(fileMap); // Pass file map to parent via callback
    }
  };

  // Handle input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  // Handle drag-and-drop functionality
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    handleFiles(event.dataTransfer.files);
  };

  return (
    <Box marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor="file-input" required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <Box
          border="1px solid"
          borderColor={dragging ? 'blue.400' : borderColor}
          bgColor={dragging ? 'blue.50' : dropzoneBg}
          p={6}
          rounded="md"
          textAlign="center"
          boxShadow="md"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          cursor="pointer"
          _hover={{ borderColor: 'blue.400' }}
          onClick={() => document.getElementById('file-input')?.click()} // Trigger file input click
        >
          {fileNames.length > 0 ? (
            <Text fontSize="sm" mb={2}>
              {fileNames.join(', ')} {/* Display file name(s) */}
            </Text>
          ) : (
            <>
              <Icon as={FiUpload} boxSize={6} color="gray.500" mb={2} />
              <Text fontSize="sm" color="gray.500">
                {placeholder || 'Select file or drag it here (5 MB file upload limit)'}
              </Text>
            </>
          )}
        </Box>
        <input
          id="file-input"
          type="file"
          style={{ display: 'none' }}
          multiple={multiple}
          onChange={handleFileChange}
        />
      </FormControl>
    </Box>
  );
};
